import React from 'react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';


import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

// import styles from './client.css'


import companyN1 from '.././../asset/pr2.png';
import companyN2 from '.././../asset/pr3.png';
import companyN3 from '.././../asset/pr4.png';
import companyN4 from '.././../asset/pr5.png';
import companyN5 from '.././../asset/pr7.png';
import Clientswipr from './Clientswipr';

const Client = () => {
    return (
        <div className='md:flex md:flex-row flex-wrap items-center justify-center bg-yellow-400 py-10 '>
            <div className=' md:basis-3/4 sm:basis-1/2'>


                <div className='flex flex-wrap justify-center  container'>
                    <Clientswipr />
                </div>

                {/* <div className='flex flex-wrap justify-evenly'>
                    <img className='m-2 max-w-xs max-h-20' src={companyN1} alt="" />
                    <img className='m-2 max-w-xs max-h-20' src={companyN2} alt="" />
                    <img className='m-2 max-w-xs max-h-20' src={companyN3} alt="" />
                    <img className='m-2 max-w-xs max-h-20' src={companyN4} alt="" />
                    <img className='m-2 max-w-xs max-h-20' src={companyN5} alt="" />
                </div> */}

            </div>
            <div className='border-r-2 border-slate-800'></div>
            <div className=' md:basis-5/6 lg:basis-3/12 sm:basis-1/2 lg:max-w-xs max-h-96 mx-5'>
                <h1 className='lg:text-5xl md:text-3xl text-xl font-bold text-center lg:text-left text-black'>Many Has Feel  Our Magic.</h1>
            </div>
        </div>
    );
};

export default Client;