import React, { useRef } from 'react';
import { motion, useInView } from "framer-motion"
import PricingHypeCard from './PricingHypeCard';


const pricingCards = [
    {
        id: 1,
        plans: 'Basic plan',
        price: '$99,99',
        discription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus.\r\n'
    },
    {
        id: 2,
        plans: 'Medium plan',
        price: '$109,99',
        discription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus.\r\n'
    },
    {
        id: 3,
        plans: 'Premium Plan',
        price: '$199,99',
        discription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus.\r\n'
    },
    {
        id: 4,
        plans: 'enterprise plan',
        price: '$499,99',
        discription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus.\r\n'
    },
]

const pricesCard = {
    hidden: { y: '100vh', opacity: -10 },
    visible: {
        y: 0,
        opacity: 1
    }
}


const PricingHypebeat = () => {
    const ref = useRef(null)
    const isInView = useInView(ref, { once: true })
    return (
        <div ref={ref} className='  h-full  bg-white '>
            <div className=' container mx-auto'>
                <div className=' text-center  '>
                    <h6 className=' md:text-xl font-bold text-orange-500 mb-5 text-sm ' >Pricing package</h6>
                    <h2 className=' font-bold  capitalize md:text-5xl md:mb-20 text-2xl  text-black '>Successful Psychographics, Dominant Factor.</h2>
                </div>

                <div className=' flex justify-center items-center flex-col md:flex-wrap lg:flex-nowrap md:flex-row mx-10 '>
                    {
                        pricingCards.map((pricingCard, i) => (
                            isInView && <motion.div
                                variants={pricesCard}
                                initial="hidden"
                                animate="visible"
                                transition={{
                                    duration: 0.3,
                                    type: 'spring',
                                    delay: i * 0.4,
                                    staggerChildren: 0.5,
                                    staggerDirection: 1,
                                    damping: 15,
                                    stiffness: 100,
                                    restDelta: 0.001,
                                    ease: 'easeInOut'
                                }}

                            > <PricingHypeCard key={pricingCard.id} pricingCard={pricingCard}></PricingHypeCard></motion.div>))
                    }
                </div>
            </div>
        </div>
    );
};

export default PricingHypebeat;