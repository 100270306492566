import React from 'react';
import AboutHypebeat from '../AboutHypebeat/AboutHypebeat';
import BlogHypebeat from '../BlogHypebeat/BlogHypebeat';
import Client from '../Client/Client';
import Footer from '../Footer/Footer';
import HeroHypebeat from '../HeroHypebeat/HeroHypebeat';
import InfluencersSuccess from '../InfluencersSuccess/InfluencersSuccess';
import PricingHypebeat from '../PricingHypebeat/PricingHypebeat';
import PromoteHypebeat from '../PromoteHypebeat/PromoteHypebeat';
import ServiceHypebeat from '../ServiceHypebeat/ServiceHypebeat';
import Testimonial from '../Testimonial/Testimonial';
import WhyHypebeat from '../WhyHypebeat/WhyHypebeat';

const Home = () => {
    return (
        <div>
            <HeroHypebeat />
            <Client />
            <AboutHypebeat />
            <InfluencersSuccess />
            <WhyHypebeat />
            <ServiceHypebeat />
            <PromoteHypebeat />
            <PricingHypebeat />
            <Testimonial />
            <BlogHypebeat />
            <Footer />

        </div>
    );
};

export default Home;