import { motion, useInView } from 'framer-motion';
import React, { useRef } from 'react';
import ServiceHypebeatCard from './ServiceHypebeatCard';

const services = [
    {
        id: 1,
        serviceName: 'Product Placement',
        servicedetail: 'Cras mattis congue consequat. Aenean finibus at ipsum id accumsan. Nunc ut fermentum tortor\r\n',
    },
    {
        id: 2,
        serviceName: 'Harris Kendra ',
        servicedetail: 'Duis neque ipsum, ullamcorper in nisl in, suscipit rutrum ipsum sed non faucibus mi fusce blandit ultricies\r\n',

    },
    {
        id: 3,
        serviceName: 'Nadra Raris',
        servicedetail: 'Mauris arcu sapien, euismod nec sagittis et, luctus ac nulla. Vivamus fringilla sapien sapien\r\n',

    },
    {
        id: 4,
        serviceName: 'Draken Warris',
        servicedetail: 'Etiam convallis, quam ac hendrerit euismod, lorem neque efficitur lorem, sed faucibus ligula dolor at orci\r\n',

    },
]

const serviceCard = {
    hidden: { y: '100vh', opacity: -10 },
    visible: {
        y: 0,
        opacity: 1
    }
}


const ServiceHypebeat = () => {
    const ref = useRef(null)
    const isInView = useInView(ref, { once: true })

    return (
        <div className=' lg:h-screen h-full bg-white'>
            <div className="bg-[url('https://i.ibb.co/DYmLPCL/bg1-2.png')] bg-cover bg-no-repeat w-60 h-60 absolute"></div>
            <div className="flex justify-center items-center flex-col flex-wrap lg:p-5 pt-40  ">

                <h6 className="text-center text-xl uppercase text-orange-400 font-bold mb-5 ">Our services</h6>
                <h2 className="text-2xl md:text-5xl  md:mt-10 capitalize font-bold text-slate-800 text-center  " >Endless Possibilities With <br /> Influencer.</h2>

                <div ref={ref} className=' mt-10 flex flex-row gap-5 flex-wrap justify-center items-center'>
                    {
                        services.map((service, i) => (isInView && <motion.div
                            variants={serviceCard}
                            initial="hidden"
                            animate="visible"
                            transition={{
                                duration: 0.3,
                                type: 'spring',
                                delay: i * 0.4,
                                staggerChildren: 0.5,
                                staggerDirection: 1,
                                damping: 10,
                                stiffness: 70,
                                restDelta: 0.001,
                                ease: 'easeInOut'
                            }}

                        >
                            <ServiceHypebeatCard key={service.id} service={service} ></ServiceHypebeatCard>
                        </motion.div>))


                    }


                </div>
            </div>

        </div>
    );
};

export default ServiceHypebeat;