import React, { useRef } from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { motion, useInView } from "framer-motion";
import BlogHypeCard from './BlogHypeCard';


const blogs = [
    {
        id: 1,
        title: 'Successful Psychographics, Dominant Factorc ',
        discription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla condimentum risus ac lorem semper maximus. Fusce et accumsan ante. Aliquam\r\n',
        image: 'https://i.ibb.co/M9CK22W/young-milenial-influencers-sharing-creative-conten-2021-08-30-10-26-19-utc.jpg'
    },
    {
        id: 2,
        title: 'The real influencers produce great content',
        discription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla condimentum risus ac lorem semper maximus. Fusce et accumsan ante. Aliquam\r\n',
        image: 'https://i.ibb.co/z6M9dBP/influencers-sitting-around-the-table-vlogging-2022-05-18-19-55-39-utc.jpg'
    },
    {
        id: 3,
        title: 'Startup your career as a social media influencer',
        discription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla condimentum risus ac lorem semper maximus. Fusce et accumsan ante. Aliquam\r\n',
        image: 'https://i.ibb.co/9gByX92/young-caucasian-female-influencer-recording-video-2022-02-17-02-31-36-utc.jpg'
    },

]

const blogshead = {
    hidden: { opacity: 0, scale: .65 },
    visible: {
        opacity: 1,
        scale: 1,
        transition: {
            type: 'spring',
            delay: 0.5,
            damping: 5,
            stiffness: 100,
            restDelta: 0.001
        }
    }
}

const BlogHypebeat = () => {
    const ref = useRef(null)
    const isInView = useInView(ref, { once: true }, { margin: '100px' })
    return (

        <div ref={ref} className=' w-full h-full lg:h-screen bg-white py-40 lg:py-[100px]'>
            <div className='container mx-auto '>
                {isInView &&
                    <motion.div
                        variants={blogshead}
                        initial="hidden"
                        animate="visible"
                        className=' bg-black rounded-2xl lg:mx-20 mx-5 flex justify-center items-center flex-col md:flex-row md:items-center md:justify-between p-3 '>
                        <div className=' text-center md:text-left '>
                            <h6 className=' font-bold uppercase md:text-xl text-sm text-white mt-3 md:ml-5'>our blog</h6>
                            <h2 className=' font-bold lg:text-5xl md:text-3xl text-2xl  capitalize text-white mt-3 md:ml-5'>our latest Blog & Article</h2>
                        </div>
                        <div className='md:p-2 md:mr-5 md:flex md:items-end md:justify-end '>
                            <button className=' w-40 text-lg px-4 py-4 font-bold rounded-2xl hover:bg-orange-400 bg-yellow-400  text-white '>
                                <span className='flex justify-center items-center '>
                                    Read More
                                    <AiOutlineArrowRight className='ml-2 h-5 w-5' />
                                </span>  </button>
                        </div>
                    </motion.div>}

                <div className=' flex justify-center items-center  lg:flex-row flex-col lg:mx-20 mx-5'>
                    {
                        blogs.map(blog => <BlogHypeCard key={blog.id} blog={blog} ></BlogHypeCard>)
                    }
                </div>


            </div>
        </div>





    );
};

export default BlogHypebeat;