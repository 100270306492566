import React from 'react';
import companyN1 from '.././../asset/pr2.png';
import companyN2 from '.././../asset/pr3.png';
import companyN3 from '.././../asset/pr4.png';
import companyN4 from '.././../asset/pr5.png';
import companyN5 from '.././../asset/pr7.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import styles from './clientswipr.css'

const Clientswipr = () => {
    return (
        <div className='swiper-container'>
            <Swiper
                // spaceBetween={5}
                // slidesPerView={6}
                // onSlideChange={() => console.log('slide change')}
                // onSwiper={(swiper) => console.log(swiper)}
                // centeredSlides={true}
                // autoplay={{
                //     delay: 1000,
                //     // disableOnInteraction: false,
                // }}
                // modules={[Autoplay]}

                spaceBetween={0}
                slidesPerView={3}
                centeredSlides={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                loop={true}
                modules={[Autoplay, Pagination, Navigation]}
                breakpoints={{

                    600: {
                        width: 600,
                        slidesPerView: 1,
                    },
                    700: {
                        width: 700,
                        slidesPerView: 3,
                    },

                    1024: {
                        width: 768,
                        slidesPerView: 3,
                    },
                }}
            // className='flex flex-wrap justify-evenly'
            >
                <SwiperSlide><img className='m-2 swiper-image max-h-20 ' src={companyN1} alt="" /></SwiperSlide>
                <SwiperSlide><img className='m-2 swiper-image max-h-20 ' src={companyN2} alt="" /></SwiperSlide>
                <SwiperSlide><img className='m-2 swiper-image max-h-20 ' src={companyN3} alt="" /></SwiperSlide>
                <SwiperSlide><img className='m-2 swiper-image max-h-20 ' src={companyN4} alt="" /></SwiperSlide>
                <SwiperSlide><img className='m-2 swiper-image max-h-20 ' src={companyN5} alt="" /></SwiperSlide>
                <SwiperSlide><img className='m-2 swiper-image max-h-20 ' src={companyN3} alt="" /></SwiperSlide>
                <SwiperSlide><img className='m-2 swiper-image max-h-20 ' src={companyN4} alt="" /></SwiperSlide>
                <SwiperSlide><img className='m-2 swiper-image max-h-20 ' src={companyN5} alt="" /></SwiperSlide>

            </Swiper>
        </div>
    );
};

export default Clientswipr;