import React, { useRef } from 'react';
import { motion, useAnimation, useInView } from "framer-motion"
import Influencer from './influencer';

const influencerlist = [
    {
        id: 1,
        name: 'Kendra Harris',
        discription: 'Lorem ipsum dolor sit amet, consectetur  adipiscing elit. Ut elit tellus.\r\n',
        picture: 'https://i.ibb.co/XpG7x43/woman-spends-leisure-in-friend-circle-studio-shot-2022-02-08-05-17-28-utc.png'
    },
    {
        id: 2,
        name: 'Harris Kendra ',
        discription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus.\r\n',
        picture: 'https://i.ibb.co/8DqfmJc/portrait-of-young-cool-dark-skinned-man-with-afro-2022-02-08-03-59-51-utc.png'
    },
    {
        id: 3,
        name: 'Nadra Raris',
        discription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus.\r\n',
        picture: 'https://i.ibb.co/RjXHWPY/surprised-european-guy-keeps-mouth-opened-from-won-2022-02-06-07-46-51-utc.png'
    },
    {
        id: 4,
        name: 'Draken Warris',
        discription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus.\r\n',
        picture: 'https://i.ibb.co/Mg3yPQg/emotive-attractive-caucasian-woman-looking-at-came-2022-02-07-22-21-55-utc.png'
    },
]

const influencerCard = {
    hidden: { opacity: -5, y: '100vh' },
    visible: {
        opacity: 1,
        y: 0,

    }
}
const influencers = {
    hidden: { scale: 0, opacity: -20 },
    visible: {
        scale: 1,
        opacity: 1

    }
}


const InfluencersSuccess = () => {
    const ref = useRef(null)
    const isInView = useInView(ref, { once: true })

    return (
        <div ref={ref} className='  h-full  '>
            <div className="bg-[url('https://i.ibb.co/DYmLPCL/bg1-2.png')] bg-contain bg-no-repeat h-60 w-60 absolute right-0 rotate-180"></div>
            {
                isInView && <motion.div
                    variants={influencers}
                    initial="hidden"
                    animate="visible"
                    className="relative w-full flex lg:justify-center justify-start items-center lg:flex-row flex-col " >

                    <div
                        className=" absolute bg-[url('https://i.ibb.co/98Tb1KD/background-1-1.png')]  bg-cover bg-black h-[400px] md:h-[600px] w-[79%] md:w-2/3 px-5 " >
                        <h6 className=' text-white text-center text-sm md:text-lg uppercase pt-5 pb-5 '>Successful Influencer, Done Right</h6>
                        <h2 className=' text-white text-center text-2xl  md:text-[40px] font-bold capitalize '>meet our influencer</h2>
                    </div>

                    <div className='w-full flex justify-center items-center md:flex-row md:flex-wrap flex-col mt-[150px] lg:mt-[250px]'>
                        {
                            influencerlist.map((influencer, i) => (isInView && <motion.div className=' '>
                                <motion.div

                                    variants={influencerCard}
                                    initial="hidden"
                                    animate="visible"
                                    transition={{
                                        duration: 3,
                                        type: 'spring',
                                        delay: i * .3,
                                        staggerChildren: 0.5,
                                        staggerDirection: 1,
                                        damping: 10,
                                        stiffness: 70,
                                        restDelta: 0.001,
                                        ease: 'easeInOut'
                                    }}
                                >
                                    <Influencer
                                        key={influencer.id} influencer={influencer}  ></Influencer></motion.div></motion.div>)
                            )
                        }
                    </div>


                </motion.div>
            }


        </div>
    );
};

export default InfluencersSuccess;