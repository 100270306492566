import React from 'react';
import { FaFacebook } from 'react-icons/fa';
import { TiSocialTwitter } from 'react-icons/ti';
import { BsYoutube } from 'react-icons/bs';


const Influencer = ({ influencer }) => {

    const { name, picture, bg, discription } = influencer

    return (
        <div className='shadow-xl p-3 rounded-3xl bg-white m-3'>

            <div className=' w-72  h-4/5  '>
                <div className='flex justify-center'>
                    <img className=' bg-pink-400 h-72 w-full  rounded-2xl ' src={picture} alt="" />
                </div>

                <h4 className=' mt-5 mb-2 text-2xl capitalize font-semibold text-center text-black'>{name}</h4>
                <div className='border-t-2 border-slate-200  border-dotted '></div>
                <p className=' mt-5 mb-10  text-center text-black'>{discription}</p>
                <div className='flex justify-center items-center mb-20'>
                    <FaFacebook className='mx-2 h-5 w-5 text-black' />
                    <TiSocialTwitter className='h-5 w-5  text-black' />
                    <BsYoutube className='mx-2 h-5 w-5  text-black' />
                </div>
            </div>
        </div>
    );
};

export default Influencer;