import React from 'react';
import { BsFillPersonFill } from 'react-icons/bs';
import { BiMessageSquare } from 'react-icons/bi';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import Tooltip from './Tooltip';
import { AiOutlinePhone } from 'react-icons/ai';
import { FiMessageSquare } from 'react-icons/fi';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import styles from './profile.css'

const Profiles = ({ employe }) => {

    const { EmployeeNameOne,
        EmployeePicOne,
        SkillPic,
        EmployeeOneDesignation,
        EmployeeNameTwo,
        EmployeeTwoDesignation,
        EmployeeTwoDetails,
        EmployeeTwoLocation,
        EmployeeNameThree,
        EmployeePicThree,
        EmployeeThreeDetails,
        EmployeeThreeDesignation,
        EmployeeThreeLocation,
        EmployeeNameFour,
        EmployeeFourDesignation,
        EmployeeFourDetails,
        EmployeeFourLocation,
        EmployeeNameFive,
        EmployeeFiveDesignation,
        EmployeeFiveDetails,
        EmployeeFiveLocation,
        EmployeePicFive } = employe

    console.log(employe);

    return (
        <div className='w-full  bg-white flex items-center justify-center flex-col rounded-md shadow-lg'>



            {/* <div className='pt-5 pb-3 '>
                <Tippy content={<Tooltip style={{ color: "white" }} data-background-color="white" borderColor='white' />} >
                    <img className='w-20 h-20 rounded-full' src={EmployeePicOne} alt="" />
                </Tippy>
            </div> */}
            <div className='pt-5 pb-3  '>
                <Tippy className='tippy-tooltip  tippy-popper shadow-lg' content={

                    <div className='w-80 h-40 rounded-xl bg-white p-5'>
                        <div className=' flex justify-between items-center'>
                            <div className=' pb-3 flex-1'>
                                <img className='w-16 h-16 rounded-full' src={EmployeePicOne} alt="" />
                                {/* <img className='w-10 h-10 rounded-full' src={SkillPic} alt="" /> */}
                            </div>
                            <div className=' flex-3 w-[70%]'>
                                <div className='flex justify-between items-center  w-full'>
                                    <h1 className=' text-sm font-bold text-black'>Name</h1>
                                    <p className=' text-xs text-gray-400'>Country</p>
                                </div>
                                <h1 className=' text-purple-500'>Designation</h1>
                                <p className=' text-xs text-gray-400' >Lorem ipsum dolor sit, amet consectetur adipisicing elit. </p>


                            </div>
                        </div>

                        <div className=' flex justify-end items-center py-5 '>
                            <AiOutlinePhone className='ml-3  text-gray-400' />
                            <FiMessageSquare className='ml-3 text-gray-400' />
                            <BiDotsHorizontalRounded className='ml-3 text-gray-400' />
                        </div>
                    </div>} >
                    <img className='w-20 h-20 rounded-full' src={EmployeePicOne} alt="" />
                </Tippy>
            </div>

            <h1 className=' text-base text-[#283252] text-center'>Tara Svenson</h1>
            <p className=' text-xs text-[#a2a5b9] text-center'>UI/UX Designer</p>
            <div className='flex justify-center items-center mt-5 mb-7'>
                <Tippy className='tippy-tooltip  tippy-popper shadow-lg' content={

                    <div className='w-80 h-40 rounded-xl bg-white p-5'>
                        <div className=' flex justify-between items-center'>
                            <div className=' pb-3 flex-1'>
                                <img className='w-16 h-16 rounded-full' src={EmployeePicThree} alt="" />
                                {/* <img className='w-10 h-10 rounded-full' src={SkillPic} alt="" /> */}
                            </div>
                            <div className=' flex-3 w-[70%]'>
                                <div className='flex justify-between items-center  w-full'>
                                    <h1 className=' text-sm font-bold text-black'>Name</h1>
                                    <p className=' text-xs text-gray-400'>Country</p>
                                </div>
                                <h1 className=' text-purple-500'>Designation</h1>
                                <p className=' text-xs text-gray-400' >Lorem ipsum dolor sit, amet consectetur adipisicing elit. </p>


                            </div>
                        </div>

                        <div className=' flex justify-end items-center py-5 '>
                            <AiOutlinePhone className='ml-3  text-gray-400' />
                            <FiMessageSquare className='ml-3 text-gray-400' />
                            <BiDotsHorizontalRounded className='ml-3 text-gray-400' />
                        </div>
                    </div>} >
                    <img className='w-8 h-8 rounded-full mx-1' src={EmployeePicThree} alt="" />
                </Tippy>
                <Tippy className='tippy-tooltip  tippy-popper shadow-lg' content={

                    <div className='w-80 h-40 rounded-xl bg-white p-5'>
                        <div className=' flex justify-between items-center'>
                            <div className=' pb-3 flex-1'>
                                <img className='w-16 h-16 rounded-full' src={EmployeePicThree} alt="" />
                                {/* <img className='w-10 h-10 rounded-full' src={SkillPic} alt="" /> */}
                            </div>
                            <div className=' flex-3 w-[70%]'>
                                <div className='flex justify-between items-center  w-full'>
                                    <h1 className=' text-sm font-bold text-black'>Name</h1>
                                    <p className=' text-xs text-gray-400'>Country</p>
                                </div>
                                <h1 className=' text-purple-500'>Designation</h1>
                                <p className=' text-xs text-gray-400' >Lorem ipsum dolor sit, amet consectetur adipisicing elit. </p>


                            </div>
                        </div>

                        <div className=' flex justify-end items-center py-5 '>
                            <AiOutlinePhone className='ml-3  text-gray-400' />
                            <FiMessageSquare className='ml-3 text-gray-400' />
                            <BiDotsHorizontalRounded className='ml-3 text-gray-400' />
                        </div>
                    </div>} >
                    <img className='w-8 h-8 rounded-full mx-1' src={EmployeePicThree} alt="" />
                </Tippy>
                <Tippy className='tippy-tooltip  tippy-popper shadow-lg' content={

                    <div className='w-80 h-40 rounded-xl bg-white p-5'>
                        <div className=' flex justify-between items-center'>
                            <div className=' pb-3 flex-1'>
                                <img className='w-16 h-16 rounded-full' src={EmployeePicThree} alt="" />
                                {/* <img className='w-10 h-10 rounded-full' src={SkillPic} alt="" /> */}
                            </div>
                            <div className=' flex-3 w-[70%]'>
                                <div className='flex justify-between items-center  w-full'>
                                    <h1 className=' text-sm font-bold text-black'>Name</h1>
                                    <p className=' text-xs text-gray-400'>Country</p>
                                </div>
                                <h1 className=' text-purple-500'>Designation</h1>
                                <p className=' text-xs text-gray-400' >Lorem ipsum dolor sit, amet consectetur adipisicing elit. </p>


                            </div>
                        </div>

                        <div className=' flex justify-end items-center py-5 '>
                            <AiOutlinePhone className='ml-3  text-gray-400' />
                            <FiMessageSquare className='ml-3 text-gray-400' />
                            <BiDotsHorizontalRounded className='ml-3 text-gray-400' />
                        </div>
                    </div>} >
                    <img className='w-8 h-8 rounded-full mx-1' src={EmployeePicThree} alt="" />
                </Tippy>
                <Tippy className='tippy-tooltip  tippy-popper shadow-lg' content={

                    <div className='w-80 h-40 rounded-xl bg-white p-5'>
                        <div className=' flex justify-between items-center'>
                            <div className=' pb-3 flex-1'>
                                <img className='w-16 h-16 rounded-full' src={EmployeePicFive} alt="" />
                                {/* <img className='w-10 h-10 rounded-full' src={SkillPic} alt="" /> */}
                            </div>
                            <div className=' flex-3 w-[70%]'>
                                <div className='flex justify-between items-center  w-full'>
                                    <h1 className=' text-sm font-bold text-black'>Name</h1>
                                    <p className=' text-xs text-gray-400'>Country</p>
                                </div>
                                <h1 className=' text-purple-500'>Designation</h1>
                                <p className=' text-xs text-gray-400' >Lorem ipsum dolor sit, amet consectetur adipisicing elit. </p>


                            </div>
                        </div>

                        <div className=' flex justify-end items-center py-5 '>
                            <AiOutlinePhone className='ml-3  text-gray-400' />
                            <FiMessageSquare className='ml-3 text-gray-400' />
                            <BiDotsHorizontalRounded className='ml-3 text-gray-400' />
                        </div>
                    </div>} >
                    <img className='w-8 h-8 rounded-full mx-1' src={EmployeePicFive} alt="" />
                </Tippy>





            </div>

            <div className=' flex justify-center items-center pt-5 pb-5 '>
                <div className='flex justify-center items-center px-3 py-2 border-zinc-200 border-2 rounded-md text-[#a2a5b9] hover:text-[#671cc9] hover:shadow-xl transition duration-500 ease-in-out'>
                    <BsFillPersonFill />
                    <button className='pl-2 text-sm '>Profile</button>
                </div>
                <div className='flex justify-center items-center px-3 py-2 border-zinc-200 border-2  text-[#a2a5b9] hover:text-[#671cc9] hover:shadow-xl rounded-md mx-3 text-sm'>
                    <BiMessageSquare />
                    <button>Talk</button>
                </div>
            </div>

        </div>
    );
};

export default Profiles;