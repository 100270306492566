import React from 'react';
import Profiles from './Profiles';
import Tooltip from './Tooltip';
import { BsPlus } from 'react-icons/bs';
import { FiSearch } from 'react-icons/fi';
import { BsChevronDown } from 'react-icons/bs';

const Employes = [
    {
        "ID": 0,
        "EmployeeNameOne": "Cotton Hodges",
        "SkillPic": "https://i.ibb.co/dKYsYfw/javascript-39395.png",
        "EmployeePicOne": "https://i.ibb.co/n62cspp/7.jpg",
        "EmployeeOneDesignation": "Gay Martinez",
        "EmployeeNameTwo": "Vargas Guthrie",
        "EmployeeTwoDesignation": "Hampton Weber",
        "EmployeeTwoDetails": "Love Diaz",
        "EmployeeTwoLocation": "Mercedes Welch",
        "EmployeeNameThree": "Allison Young",
        "EmployeePicThree": "https://i.ibb.co/cgWBc0P/18.jpg",
        "EmployeeThreeDetails": "Barber Collier",
        "EmployeeThreeDesignation": "Heidi Lyons",
        "EmployeeThreeLocation": "Maricela Cameron",
        "EmployeeNameFour": "Hallie Mcgee",
        "EmployeeFourDesignation": "Ofelia Shepherd",
        "EmployeeFourDetails": "Zimmerman Contreras",
        "EmployeeFourLocation": "Charity Le",
        "EmployeeNameFive": "Freeman Wyatt",
        "EmployeeFiveDesignation": "Durham Gilbert",
        "EmployeeFiveDetails": "Dora Harrison",
        "EmployeeFiveLocation": "Eliza Trevino",
        "EmployeePicFive": " https://i.ibb.co/r0z2q0r/13.jpg"
    },
    {
        "ID": 1,
        "EmployeeNameOne": "Cotton Hodges",
        "SkillPic": "https://i.ibb.co/dKYsYfw/javascript-39395.png",
        "EmployeePicOne": "https://i.ibb.co/n62cspp/7.jpg",
        "EmployeeOneDesignation": "Gay Martinez",
        "EmployeeNameTwo": "Vargas Guthrie",
        "EmployeeTwoDesignation": "Hampton Weber",
        "EmployeeTwoDetails": "Love Diaz",
        "EmployeeTwoLocation": "Mercedes Welch",
        "EmployeeNameThree": "Allison Young",
        "EmployeePicThree": "https://i.ibb.co/cgWBc0P/18.jpg",
        "EmployeeThreeDetails": "Barber Collier",
        "EmployeeThreeDesignation": "Heidi Lyons",
        "EmployeeThreeLocation": "Maricela Cameron",
        "EmployeeNameFour": "Hallie Mcgee",
        "EmployeeFourDesignation": "Ofelia Shepherd",
        "EmployeeFourDetails": "Zimmerman Contreras",
        "EmployeeFourLocation": "Charity Le",
        "EmployeeNameFive": "Freeman Wyatt",
        "EmployeeFiveDesignation": "Durham Gilbert",
        "EmployeeFiveDetails": "Dora Harrison",
        "EmployeeFiveLocation": "Eliza Trevino",
        "EmployeePicFive": " https://i.ibb.co/r0z2q0r/13.jpg"
    },
    {
        "ID": 2,
        "EmployeeNameOne": "Cotton Hodges",
        "SkillPic": "https://i.ibb.co/dKYsYfw/javascript-39395.png",
        "EmployeePicOne": "https://i.ibb.co/n62cspp/7.jpg",
        "EmployeeOneDesignation": "Gay Martinez",
        "EmployeeNameTwo": "Vargas Guthrie",
        "EmployeeTwoDesignation": "Hampton Weber",
        "EmployeeTwoDetails": "Love Diaz",
        "EmployeeTwoLocation": "Mercedes Welch",
        "EmployeeNameThree": "Allison Young",
        "EmployeePicThree": "https://i.ibb.co/cgWBc0P/18.jpg",
        "EmployeeThreeDetails": "Barber Collier",
        "EmployeeThreeDesignation": "Heidi Lyons",
        "EmployeeThreeLocation": "Maricela Cameron",
        "EmployeeNameFour": "Hallie Mcgee",
        "EmployeeFourDesignation": "Ofelia Shepherd",
        "EmployeeFourDetails": "Zimmerman Contreras",
        "EmployeeFourLocation": "Charity Le",
        "EmployeeNameFive": "Freeman Wyatt",
        "EmployeeFiveDesignation": "Durham Gilbert",
        "EmployeeFiveDetails": "Dora Harrison",
        "EmployeeFiveLocation": "Eliza Trevino",
        "EmployeePicFive": " https://i.ibb.co/r0z2q0r/13.jpg"
    },
    {
        "ID": 3,
        "EmployeeNameOne": "Cotton Hodges",
        "SkillPic": "https://i.ibb.co/dKYsYfw/javascript-39395.png",
        "EmployeePicOne": "https://i.ibb.co/n62cspp/7.jpg",
        "EmployeeOneDesignation": "Gay Martinez",
        "EmployeeNameTwo": "Vargas Guthrie",
        "EmployeeTwoDesignation": "Hampton Weber",
        "EmployeeTwoDetails": "Love Diaz",
        "EmployeeTwoLocation": "Mercedes Welch",
        "EmployeeNameThree": "Allison Young",
        "EmployeePicThree": "https://i.ibb.co/cgWBc0P/18.jpg",
        "EmployeeThreeDetails": "Barber Collier",
        "EmployeeThreeDesignation": "Heidi Lyons",
        "EmployeeThreeLocation": "Maricela Cameron",
        "EmployeeNameFour": "Hallie Mcgee",
        "EmployeeFourDesignation": "Ofelia Shepherd",
        "EmployeeFourDetails": "Zimmerman Contreras",
        "EmployeeFourLocation": "Charity Le",
        "EmployeeNameFive": "Freeman Wyatt",
        "EmployeeFiveDesignation": "Durham Gilbert",
        "EmployeeFiveDetails": "Dora Harrison",
        "EmployeeFiveLocation": "Eliza Trevino",
        "EmployeePicFive": " https://i.ibb.co/r0z2q0r/13.jpg"
    },
    {
        "ID": 4,
        "EmployeeNameOne": "Cotton Hodges",
        "SkillPic": "https://i.ibb.co/dKYsYfw/javascript-39395.png",
        "EmployeePicOne": "https://i.ibb.co/n62cspp/7.jpg",
        "EmployeeOneDesignation": "Gay Martinez",
        "EmployeeNameTwo": "Vargas Guthrie",
        "EmployeeTwoDesignation": "Hampton Weber",
        "EmployeeTwoDetails": "Love Diaz",
        "EmployeeTwoLocation": "Mercedes Welch",
        "EmployeeNameThree": "Allison Young",
        "EmployeePicThree": "https://i.ibb.co/cgWBc0P/18.jpg",
        "EmployeeThreeDetails": "Barber Collier",
        "EmployeeThreeDesignation": "Heidi Lyons",
        "EmployeeThreeLocation": "Maricela Cameron",
        "EmployeeNameFour": "Hallie Mcgee",
        "EmployeeFourDesignation": "Ofelia Shepherd",
        "EmployeeFourDetails": "Zimmerman Contreras",
        "EmployeeFourLocation": "Charity Le",
        "EmployeeNameFive": "Freeman Wyatt",
        "EmployeeFiveDesignation": "Durham Gilbert",
        "EmployeeFiveDetails": "Dora Harrison",
        "EmployeeFiveLocation": "Eliza Trevino",
        "EmployeePicFive": " https://i.ibb.co/r0z2q0r/13.jpg"
    },
    {
        "ID": 5,
        "EmployeeNameOne": "Cotton Hodges",
        "SkillPic": "https://i.ibb.co/dKYsYfw/javascript-39395.png",
        "EmployeePicOne": "https://i.ibb.co/n62cspp/7.jpg",
        "EmployeeOneDesignation": "Gay Martinez",
        "EmployeeNameTwo": "Vargas Guthrie",
        "EmployeeTwoDesignation": "Hampton Weber",
        "EmployeeTwoDetails": "Love Diaz",
        "EmployeeTwoLocation": "Mercedes Welch",
        "EmployeeNameThree": "Allison Young",
        "EmployeePicThree": "https://i.ibb.co/cgWBc0P/18.jpg",
        "EmployeeThreeDetails": "Barber Collier",
        "EmployeeThreeDesignation": "Heidi Lyons",
        "EmployeeThreeLocation": "Maricela Cameron",
        "EmployeeNameFour": "Hallie Mcgee",
        "EmployeeFourDesignation": "Ofelia Shepherd",
        "EmployeeFourDetails": "Zimmerman Contreras",
        "EmployeeFourLocation": "Charity Le",
        "EmployeeNameFive": "Freeman Wyatt",
        "EmployeeFiveDesignation": "Durham Gilbert",
        "EmployeeFiveDetails": "Dora Harrison",
        "EmployeeFiveLocation": "Eliza Trevino",
        "EmployeePicFive": " https://i.ibb.co/r0z2q0r/13.jpg"
    },
    {
        "ID": 6,
        "EmployeeNameOne": "Cotton Hodges",
        "SkillPic": "https://i.ibb.co/dKYsYfw/javascript-39395.png",
        "EmployeePicOne": "https://i.ibb.co/n62cspp/7.jpg",
        "EmployeeOneDesignation": "Gay Martinez",
        "EmployeeNameTwo": "Vargas Guthrie",
        "EmployeeTwoDesignation": "Hampton Weber",
        "EmployeeTwoDetails": "Love Diaz",
        "EmployeeTwoLocation": "Mercedes Welch",
        "EmployeeNameThree": "Allison Young",
        "EmployeePicThree": "https://i.ibb.co/cgWBc0P/18.jpg",
        "EmployeeThreeDetails": "Barber Collier",
        "EmployeeThreeDesignation": "Heidi Lyons",
        "EmployeeThreeLocation": "Maricela Cameron",
        "EmployeeNameFour": "Hallie Mcgee",
        "EmployeeFourDesignation": "Ofelia Shepherd",
        "EmployeeFourDetails": "Zimmerman Contreras",
        "EmployeeFourLocation": "Charity Le",
        "EmployeeNameFive": "Freeman Wyatt",
        "EmployeeFiveDesignation": "Durham Gilbert",
        "EmployeeFiveDetails": "Dora Harrison",
        "EmployeeFiveLocation": "Eliza Trevino",
        "EmployeePicFive": " https://i.ibb.co/r0z2q0r/13.jpg"
    },
    {
        "ID": 7,
        "EmployeeNameOne": "Cotton Hodges",
        "SkillPic": "https://i.ibb.co/dKYsYfw/javascript-39395.png",
        "EmployeePicOne": "https://i.ibb.co/n62cspp/7.jpg",
        "EmployeeOneDesignation": "Gay Martinez",
        "EmployeeNameTwo": "Vargas Guthrie",
        "EmployeeTwoDesignation": "Hampton Weber",
        "EmployeeTwoDetails": "Love Diaz",
        "EmployeeTwoLocation": "Mercedes Welch",
        "EmployeeNameThree": "Allison Young",
        "EmployeePicThree": "https://i.ibb.co/cgWBc0P/18.jpg",
        "EmployeeThreeDetails": "Barber Collier",
        "EmployeeThreeDesignation": "Heidi Lyons",
        "EmployeeThreeLocation": "Maricela Cameron",
        "EmployeeNameFour": "Hallie Mcgee",
        "EmployeeFourDesignation": "Ofelia Shepherd",
        "EmployeeFourDetails": "Zimmerman Contreras",
        "EmployeeFourLocation": "Charity Le",
        "EmployeeNameFive": "Freeman Wyatt",
        "EmployeeFiveDesignation": "Durham Gilbert",
        "EmployeeFiveDetails": "Dora Harrison",
        "EmployeeFiveLocation": "Eliza Trevino",
        "EmployeePicFive": " https://i.ibb.co/r0z2q0r/13.jpg"
    },
    {
        "ID": 8,
        "EmployeeNameOne": "Cotton Hodges",
        "SkillPic": "https://i.ibb.co/dKYsYfw/javascript-39395.png",
        "EmployeePicOne": "https://i.ibb.co/n62cspp/7.jpg",
        "EmployeeOneDesignation": "Gay Martinez",
        "EmployeeNameTwo": "Vargas Guthrie",
        "EmployeeTwoDesignation": "Hampton Weber",
        "EmployeeTwoDetails": "Love Diaz",
        "EmployeeTwoLocation": "Mercedes Welch",
        "EmployeeNameThree": "Allison Young",
        "EmployeePicThree": "https://i.ibb.co/cgWBc0P/18.jpg",
        "EmployeeThreeDetails": "Barber Collier",
        "EmployeeThreeDesignation": "Heidi Lyons",
        "EmployeeThreeLocation": "Maricela Cameron",
        "EmployeeNameFour": "Hallie Mcgee",
        "EmployeeFourDesignation": "Ofelia Shepherd",
        "EmployeeFourDetails": "Zimmerman Contreras",
        "EmployeeFourLocation": "Charity Le",
        "EmployeeNameFive": "Freeman Wyatt",
        "EmployeeFiveDesignation": "Durham Gilbert",
        "EmployeeFiveDetails": "Dora Harrison",
        "EmployeeFiveLocation": "Eliza Trevino",
        "EmployeePicFive": " https://i.ibb.co/r0z2q0r/13.jpg"
    },
    {
        "ID": 9,
        "EmployeeNameOne": "Cotton Hodges",
        "SkillPic": "https://i.ibb.co/dKYsYfw/javascript-39395.png",
        "EmployeePicOne": "https://i.ibb.co/n62cspp/7.jpg",
        "EmployeeOneDesignation": "Gay Martinez",
        "EmployeeNameTwo": "Vargas Guthrie",
        "EmployeeTwoDesignation": "Hampton Weber",
        "EmployeeTwoDetails": "Love Diaz",
        "EmployeeTwoLocation": "Mercedes Welch",
        "EmployeeNameThree": "Allison Young",
        "EmployeePicThree": "https://i.ibb.co/cgWBc0P/18.jpg",
        "EmployeeThreeDetails": "Barber Collier",
        "EmployeeThreeDesignation": "Heidi Lyons",
        "EmployeeThreeLocation": "Maricela Cameron",
        "EmployeeNameFour": "Hallie Mcgee",
        "EmployeeFourDesignation": "Ofelia Shepherd",
        "EmployeeFourDetails": "Zimmerman Contreras",
        "EmployeeFourLocation": "Charity Le",
        "EmployeeNameFive": "Freeman Wyatt",
        "EmployeeFiveDesignation": "Durham Gilbert",
        "EmployeeFiveDetails": "Dora Harrison",
        "EmployeeFiveLocation": "Eliza Trevino",
        "EmployeePicFive": " https://i.ibb.co/r0z2q0r/13.jpg"
    },
    {
        "ID": 10,
        "EmployeeNameOne": "Cotton Hodges",
        "SkillPic": "https://i.ibb.co/dKYsYfw/javascript-39395.png",
        "EmployeePicOne": "https://i.ibb.co/n62cspp/7.jpg",
        "EmployeeOneDesignation": "Gay Martinez",
        "EmployeeNameTwo": "Vargas Guthrie",
        "EmployeeTwoDesignation": "Hampton Weber",
        "EmployeeTwoDetails": "Love Diaz",
        "EmployeeTwoLocation": "Mercedes Welch",
        "EmployeeNameThree": "Allison Young",
        "EmployeePicThree": "https://i.ibb.co/cgWBc0P/18.jpg",
        "EmployeeThreeDetails": "Barber Collier",
        "EmployeeThreeDesignation": "Heidi Lyons",
        "EmployeeThreeLocation": "Maricela Cameron",
        "EmployeeNameFour": "Hallie Mcgee",
        "EmployeeFourDesignation": "Ofelia Shepherd",
        "EmployeeFourDetails": "Zimmerman Contreras",
        "EmployeeFourLocation": "Charity Le",
        "EmployeeNameFive": "Freeman Wyatt",
        "EmployeeFiveDesignation": "Durham Gilbert",
        "EmployeeFiveDetails": "Dora Harrison",
        "EmployeeFiveLocation": "Eliza Trevino",
        "EmployeePicFive": " https://i.ibb.co/r0z2q0r/13.jpg"
    },
    {
        "ID": 11,
        "EmployeeNameOne": "Cotton Hodges",
        "SkillPic": "https://i.ibb.co/dKYsYfw/javascript-39395.png",
        "EmployeePicOne": "https://i.ibb.co/n62cspp/7.jpg",
        "EmployeeOneDesignation": "Gay Martinez",
        "EmployeeNameTwo": "Vargas Guthrie",
        "EmployeeTwoDesignation": "Hampton Weber",
        "EmployeeTwoDetails": "Love Diaz",
        "EmployeeTwoLocation": "Mercedes Welch",
        "EmployeeNameThree": "Allison Young",
        "EmployeePicThree": "https://i.ibb.co/cgWBc0P/18.jpg",
        "EmployeeThreeDetails": "Barber Collier",
        "EmployeeThreeDesignation": "Heidi Lyons",
        "EmployeeThreeLocation": "Maricela Cameron",
        "EmployeeNameFour": "Hallie Mcgee",
        "EmployeeFourDesignation": "Ofelia Shepherd",
        "EmployeeFourDetails": "Zimmerman Contreras",
        "EmployeeFourLocation": "Charity Le",
        "EmployeeNameFive": "Freeman Wyatt",
        "EmployeeFiveDesignation": "Durham Gilbert",
        "EmployeeFiveDetails": "Dora Harrison",
        "EmployeeFiveLocation": "Eliza Trevino",
        "EmployeePicFive": " https://i.ibb.co/r0z2q0r/13.jpg"
    },
    {
        "ID": 12,
        "EmployeeNameOne": "Cotton Hodges",
        "SkillPic": "https://i.ibb.co/dKYsYfw/javascript-39395.png",
        "EmployeePicOne": "https://i.ibb.co/n62cspp/7.jpg",
        "EmployeeOneDesignation": "Gay Martinez",
        "EmployeeNameTwo": "Vargas Guthrie",
        "EmployeeTwoDesignation": "Hampton Weber",
        "EmployeeTwoDetails": "Love Diaz",
        "EmployeeTwoLocation": "Mercedes Welch",
        "EmployeeNameThree": "Allison Young",
        "EmployeePicThree": "https://i.ibb.co/cgWBc0P/18.jpg",
        "EmployeeThreeDetails": "Barber Collier",
        "EmployeeThreeDesignation": "Heidi Lyons",
        "EmployeeThreeLocation": "Maricela Cameron",
        "EmployeeNameFour": "Hallie Mcgee",
        "EmployeeFourDesignation": "Ofelia Shepherd",
        "EmployeeFourDetails": "Zimmerman Contreras",
        "EmployeeFourLocation": "Charity Le",
        "EmployeeNameFive": "Freeman Wyatt",
        "EmployeeFiveDesignation": "Durham Gilbert",
        "EmployeeFiveDetails": "Dora Harrison",
        "EmployeeFiveLocation": "Eliza Trevino",
        "EmployeePicFive": " https://i.ibb.co/r0z2q0r/13.jpg"
    }
]

const EmployeeDashboard = () => {
    return (
        <div className=' w-full flex justify-center flex-col items-center '>
            <div className='w-[80%]'>

                <div className='w-full flex justify-between flex-col lg:flex-row py-10'>
                    <div className='flex justify-center items-center  '>
                        <div className=' relative  '>
                            <FiSearch className=' absolute left-2 -top-2  ' />
                        </div>

                        <input className='bg-white w-48  text-xs pl-7 py-2 rounded-sm outline-none border-2 border-gray-100 focus:shadow-sm' placeholder='Search...' type="text" />
                    </div>

                    <div className=' flex justify-center items-center '>
                        <div className='flex justify-center items-center mr-3'>
                            <select className="  appearance-none bg-transparent py-1 rounded-sm outline-none border-2 border-gray-100 focus:shadow-sm pl-3 text-sm text-gray-300">
                                <option className='text-xm lg:text-sm'>Web Development</option>
                                <option>UI/Ux Designer</option>
                                <option>Programmer</option>
                                <option>Database Administrator</option>
                                <option>Web Development</option>
                                <option>UI/Ux Designer</option>
                                <option>Programmer</option>
                            </select>
                            <div className='relative'>
                                <BsChevronDown className=' absolute -left-7 -bottom-2 text-gray-300' />
                            </div>

                        </div>


                        <div className=' flex justify-center items-center text-white bg-purple-700 p-2 rounded-md'>
                            <BsPlus />
                            <button className=' text-sm cursor-pointer'>Add User</button>
                        </div>
                    </div>
                </div>

                <div className=' grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1  gap-2 lg:gap-4 justify-items-center  '>
                    {
                        Employes.map(employe => <Profiles key={employe.ID} employe={employe} />)
                    }

                </div>
            </div>
        </div>
    );
};

export default EmployeeDashboard;