import React, { useRef } from 'react';
import { BsPlayFill } from 'react-icons/bs';
import imgMan from '../../asset/about (1).jpg'
import imgPeople from '../../asset/about (2).jpg'
import backgroundimageg from '../../asset/background (2).png'
import { BsCheck } from 'react-icons/bs';
import { motion, useAnimation } from "framer-motion"
import { useInView } from "framer-motion"

const mainimage = {
    hidden: { opacity: 0, scale: .65 },
    visible: {
        opacity: 1,
        scale: 1,
        transition: {
            type: 'spring',
            delay: 0.5,
            damping: 3,
            duration: 3,
            stiffness: 60,
            restDelta: 0.001,
            ease: 'easeInOut'
        }
    }
}
const topimage = {
    hidden: { y: '60vh', opacity: 0 },
    visible: {
        opacity: 1,
        y: 1,
        transition: {
            type: 'spring',
            delay: 1.3,
            damping: 15,
            stiffness: 60,
            restDelta: 0.001,
            ease: 'easeInOut',
        }
    }
}
const bottomimage = {
    hidden: { x: '-100vw' },
    visible: {
        x: 1,

        transition: {
            type: 'spring',
            delay: 1.3,
            damping: 15,
            stiffness: 60,
            restDelta: 0.001,
            ease: 'easeInOut',
        }
    }
}

const AboutHypebeat = () => {
    const ref = useRef(null)
    const isInView = useInView(ref, { once: true })
    return (

        <div className=' container mx-auto h-full lg:h-screen'>

            {/******************************************************************************************************************
            Container - 100% weigth  mx-auto centers lefts margine in left and right & centers h-screen takes full height of Viewport 
            *********************************************************************************************************************/}

            <div className=' flex justify-center items-center flex-col lg:flex-row flex-shrink-0 '>

                {/************************************************************************************************ 
                 Positions the IMAGE & TEXT DIV at center
                 Tailwind configures small screen first as defult so the layout would change only for large screen
                 SMALL & TAB -> Defult && LARGE -> DESIGN CHANGE FOR LARGE SCREEN
                 ************************************************************************************************/}

                <div ref={ref} className='lg:w-1/2 w-full flex justify-center items-center'>

                    {/******************************************************************************************
                      lg:w-1/2 -> 50% weigth is given to the image div for large screen and full weigth small &tab
                       Flex for positioning the image content center 
                    *******************************************************************************************/}
                    {
                        (isInView && <motion.div
                            variants={mainimage}
                            initial="hidden"
                            animate="visible"
                            className="relative bg-[url('https://i.ibb.co/98Tb1KD/background-1-1.png')] mt-16 mx-5 md:mt-40 bg-cover bg-blue-600 
     w-11/12 md:w-5/6  h-[450px] md:h-[350px] rounded-3xl z-0">
                            <div className='absolute -top-10 md:left-32'>
                                <div className=''>
                                    <BsPlayFill className='absolute md:top-4 top-3 left-4 h-14 w-14  md:h-16 md:w-16  text-white ' />
                                    <div className=' bg-yellow-400 md:h-24  md:w-24 h-20 w-20 rounded-full'></div>
                                </div>
                            </div>
                            <motion.div
                                variants={topimage}
                                initial="hidden"
                                animate="visible"
                                className='absolute right-5 top-10 md:-top-14 md:-right-5  '>
                                <div className=' relative  bg-orange-500 h-48 w-60 rounded-xl '></div>
                                <img className=' absolute top-3 -right-3 h-48 w-60 rounded-2xl' src={imgMan} alt="" />

                            </motion.div>
                            <motion.div
                                variants={bottomimage}
                                initial="hidden"
                                animate="visible"
                                className=' absolute bottom-2 left-1 md:-bottom-12 md:-left-16 lg:-left-10 -z-10'>
                                <img className="rounded-3xl md:rounded-[40px] h-48 w-60 md:w-[450px] md:h-80 " src={imgPeople} alt="" />
                            </motion.div>
                        </motion.div>)
                    }



                </div>


                <div className=' md::w-1/2 lg:w-[50%] w-full p-7 md:mt-20'>

                    {/*************************************** 
                    Another half weigth is taken for the text
                    *****************************************/}

                    <h6 className='font-medium  text-xl md:text-lg md:text-center lg:text-start uppercase text-amber-500'>about hypebeat</h6>
                    <h2 className='lg:text-5xl md:text-4xl text-2xl md:text-center lg:text-start mt-10 capitalize  font-semibold text-slate-800'>Effective Recommenders <br /> Are What We Do.</h2>
                    <p className='text-xs mt-10  text-black'>Vestibulum semper felis sit amet  lorem blandit, eu vulputate arcu sollicitudin. Nulla eu nunc sit amet quam luctus maximus. Cras eu nisi ut felis mattis suscipit et nec felis. Etiam iaculis urna sit amet massa mollis rhoncus, Sed nec massa non elit vehicula tristique.</p>

                    <div className=' flex items-center justify-start  mt-10'>
                        <div>
                            <div className='flex items-center'>
                                <BsCheck className='text-amber-400 h-5 w-5' />
                                <span className='md:text-base text-xs font-medium text-slate-600 ml-3 capitalize'>Curabitur fermentum orci</span>
                            </div>
                            <div className='flex items-center'>
                                <BsCheck className='text-amber-400 h-5 w-5' />
                                <span className='md:text-base text-xs font-medium text-slate-600 ml-3 capitalize'>Vestibulum semper felis sit</span>
                            </div>
                            <div className='flex items-center'>
                                <BsCheck className='text-amber-400 h-5 w-5' />
                                <span className='md:text-base text-xs font-medium text-slate-600 ml-3 capitalize'>fermentum Curabitur orci</span>
                            </div>
                        </div>
                        <div className='' >
                            <div className='flex items-center'>
                                <BsCheck className='text-amber-400 h-5 w-5' />
                                <span className='md:text-base text-xs font-medium text-slate-600 ml-3 capitalize'>orci Urabitur fermen tumim </span>
                            </div>
                            <div className='flex items-center'>
                                <BsCheck className='text-amber-400 h-5 w-5' />
                                <span className='md:text-base text-xs font-medium text-slate-600 ml-3 capitalize'>Curabitur orci Urabitur Curabitur </span>
                            </div>
                            <div className='flex items-center'>
                                <BsCheck className='text-amber-400 h-5 w-5' />
                                <span className='md:text-base text-xs font-medium text-slate-600 ml-3 capitalize'>Curabitur fermentum orci</span>
                            </div>
                        </div>

                    </div>
                    <div className=' flex md:justify-center md:items-center lg:justify-start'>
                        <button className='  px-8 py-4 font-bold rounded-2xl hover:bg-orange-400 bg-yellow-300  text-white mt-5'>Read More</button>
                    </div>
                </div>

            </div>

        </div>
    );
};

export default AboutHypebeat;