import React, { useRef } from 'react';
import { motion, useInView } from "framer-motion"
import CountUp from 'react-countup';
import mainIMG from '../../asset/whyus.png'
import { FiUserCheck } from 'react-icons/fi';
import { BsCheckCircleFill } from 'react-icons/bs';
import { BsEyeFill } from 'react-icons/bs';


import { BsCheckLg } from 'react-icons/bs';
import ProgressBar from "@ramonak/react-progress-bar";

const whyUsMainImage = {
    hidden: { opacity: 0, scale: .65 },
    visible: {
        opacity: 1,
        scale: 1,
        transition: {
            duration: 4,
            type: 'spring',
            delay: 0.5,
            damping: 4,
            stiffness: 60,
            restDelta: 0.001
        }
    }
}

const blueImgCard = {
    hidden: { y: '100vh', opacity: -10 },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            delay: 1.9,
            type: 'spring',
            damping: 10,
            stiffness: 100,
            restDelta: 0.001,
            ease: 'easeInOut'
        }
    }
}
const purpleImgCard = {
    hidden: { opacity: -10, y: '100vh' },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            delay: 1.5,
            type: 'spring',
            damping: 10,
            stiffness: 100,
            restDelta: 0.001,
            ease: 'easeInOut'
        }
    }
}
const orangeImgCard = {
    hidden: { opacity: -10, y: '100vh' },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            delay: 1.7,
            type: 'spring',
            damping: 10,
            stiffness: 100,
            restDelta: 0.001,
            ease: 'easeInOut'
        }
    }
}

const WhyHypebeat = () => {
    const ref = useRef(null)
    const isInView = useInView(ref, { once: true })

    return (
        <div className='   h-full lg:h-screen'>


            <div className='h-full pt-20 lg:pt-0 container mx-auto flex justify-center items-center md:flex-wrap lg:flex-nowrap lg:flex-row flex-col'>

                {isInView && <div className='lg:w-1/2 w-full flex justify-center items-center mt-36 lg:mt-0'>

                    <motion.div
                        variants={whyUsMainImage}
                        initial="hidden"
                        animate="visible"
                        className=" relative bg-yellow-500 bg-[url('https://i.ibb.co/98Tb1KD/background-1-1.png')]  rounded-3xl w-[300px] md:w-[500px] 
                        h-[263px]  lg:w-[600px]  md:h-[389px] flex justify-center ">
                        <img className='absolute -top-[15%] md:-top-1/4  ' src={mainIMG} alt="" />

                        {/* blue box */}
                        <motion.div
                            variants={blueImgCard}
                            className=' absolute drop-shadow-lg -bottom-10 -left-7 md:-bottom-10 md:-left-10 bg-blue-500 h-28 w-36 rounded-lg p-2 flex flex-col justify-center items-center'>
                            <BsEyeFill className=' bg-white border-white border-4 rounded-full h-7 w-7 text-blue-500' />
                            <span className='text-white  text-2xl font-bold'><CountUp end={5} delay={3} />M</span>
                            <h6 className='text-white text-xs font-bold capitalize'>total insight</h6>
                        </motion.div>

                        {/* pink box */}
                        <motion.div
                            variants={purpleImgCard}
                            className='absolute drop-shadow-lg -top-[100px] -left-7 md:-top-14 md:-left-10 bg-pink-400 h-28 w-36 rounded-lg p-2 flex flex-col justify-center items-center'>
                            <FiUserCheck className='bg-white border-white border-4 rounded-full h-7 w-7 text-pink-500' />
                            <span className=' text-white text-2xl  font-bold'><CountUp end={5} delay={3} />+</span>
                            <h6 className='text-white text-xs font-bold text-center'>Popular Influencer</h6>
                        </motion.div>

                        {/* orange box */}
                        <motion.div
                            variants={orangeImgCard}
                            className='absolute drop-shadow-lg -bottom-10 -right-7 md:-right-10 md:top-40  bg-orange-500 h-[99px] w-[147px] md:h-28 md:w-36 rounded-lg p-2 flex flex-col justify-center items-center'>
                            <BsCheckCircleFill className='bg-white border-white border-4 rounded-full h-7 w-7 text-orange-500' />
                            <span className='text-white box-texthead-sm text-2xl box-texthead-sm font-bold'><CountUp end={527} delay={3} />+</span>
                            <h6 className='text-white text-xs text-center font-bold'>Success Campaign</h6>
                        </motion.div>
                    </motion.div>
                </div>}




                <div ref={ref} className='p-4  lg:ml-5 lg:w-1/2 w-full mt-20 lg:mt-0'>
                    <h6 className='font-medium lg:text-left  md:mt-10 text-xl uppercase w-full text-amber-500'>why choose hypebeat</h6>
                    <h2 className=' lg:text-left mt-5 text-2xl md:text-5xl  md:mt-10 capitalize font-bold text-slate-800'>Powerful Advertiser, Effective Shaper.</h2>
                    <p className='text-base lg:text-left my-5 md:mt-10  text-black'>Vestibulum semper felis sit amet  lorem blandit, eu vulputate arcu sollicitudin. Nulla eu nunc sit amet  quam luctus maximus. Cras eu nisi ut felis </p>
                    <div className=' text-left  flex flex-col md:flex-row justify-center items-center'>
                        <div>
                            <div className='py-2' >
                                <h5 className=' text-xs font-medium  text-black'>Product Advertise</h5>
                                <ProgressBar completed={80} maxCompleted={100} completedClassName="barCompleted" barContainerClassName="containerbar" />

                            </div>
                            <div className='py-2'>
                                <h5 className='text-xs font-medium  text-black'>Campaign</h5>
                                <ProgressBar completed={85} maxCompleted={100} completedClassName="barCompleted2" barContainerClassName="containerbar" />
                            </div>
                            <div className='py-2'>
                                <h5 className='text-xs font-medium text-black'>Promotion</h5>
                                <ProgressBar completed={90} maxCompleted={100} transitionDuration="1" completedClassName="barCompleted3" barContainerClassName="containerbar" />
                            </div>
                        </div>
                        <div className=' bg-orange-600 rounded-2xl p-4 m-3 ' >
                            <h1 className=' text-xl font-semibold text-white'>A New Way To Promote Your Product</h1>
                            <div className='flex justify-center items-center flex-col'>
                                <div className='flex justify-start items-center'>
                                    <BsCheckLg className=' text-yellow-400' />
                                    <p className=' pl-2 text-white'> Curabitur Fermentum Orci</p>
                                </div>
                                <div className='flex justify-start items-center'>
                                    <BsCheckLg className=' text-yellow-400' />
                                    <p className=' pl-2 text-white'> Curabitur Fermentum Orci</p>
                                </div>
                                <div className='flex justify-start items-center'>
                                    <BsCheckLg className=' text-yellow-400' />
                                    <p className=' pl-2 text-white'> Curabitur Fermentum Orci</p>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>


        </div >
    );
};

export default WhyHypebeat;