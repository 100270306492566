import React, { useEffect, useRef } from 'react';
import CountUp from 'react-countup';
import { motion, useAnimation, useInView } from "framer-motion"
import { AiFillLike } from 'react-icons/ai';
import ImageN1 from '../../asset/emotive-attractive-caucasian-woman-looking-at-came-2022-02-07-22-21-55-utc .png'
import ImageN2 from '../../asset/close-up-studio-portrait-of-young-attractive-cauca-2022-02-08-06-01-28-utc.png'
import ImageN3 from '../../asset/portrait-of-young-cool-dark-skinned-man-with-afro-2022-02-08-03-59-51-utc.png'

const HeroHypebeat = () => {


    const ref = useRef(null)
    const isInView = useInView(ref, { once: true })
    const heroTextContainerAnimation = useAnimation()
    const heroMainImageAnimation = useAnimation()
    const heroTopImageAnimation = useAnimation()
    const heroBottomImageAnimation = useAnimation()
    useEffect(() => {
        if (isInView) {

            heroTextContainerAnimation.start({
                x: 0,
                transition: {
                    type: 'spring',
                    damping: 20,
                    stiffness: 100,
                    restDelta: 0.001,
                    duration: 0.8,
                    ease: 'easeInOut',

                }
            })

            heroMainImageAnimation.start({
                opacity: 1,
                scale: 1,
                transition: {
                    type: 'spring',
                    delay: 0.5,
                    damping: 3,
                    stiffness: 100,
                    restDelta: 0.001
                }
            })
            heroTopImageAnimation.start({
                y: 0,
                transition: {
                    type: 'spring',
                    delay: 1.5,
                    damping: 12,
                    stiffness: 100,
                    restDelta: 0.001,
                    ease: 'easeInOut',
                }
            })
            heroBottomImageAnimation.start({
                opacity: 1,
                y: 0,
                transition: {
                    type: 'spring',
                    delay: 1.5,
                    damping: 12,
                    stiffness: 100,
                    restDelta: 0.001,
                    ease: 'easeInOut',
                }
            })

        }

        if (!isInView) {
            heroTextContainerAnimation.start({ x: '-100vh' })
            heroMainImageAnimation.start({ opacity: 0, scale: .65 })
            heroTopImageAnimation.start({ y: '-100vh' })
            heroBottomImageAnimation.start({ opacity: -10, y: '90vh' })
        }


        console.log("hero text is in view: ", isInView)
    }, [isInView])

    return (
        <div ref={ref} className=" flex-col-reverse xl:flex-row flex lg:flex justify-center items-center  bg-white ">
            <div className="w-full lg:flex-1   flex-none flex flex-col justify-center  items-center lg:p-14 p-7 bg-[url('https://i.ibb.co/DYmLPCL/bg1-2.png')] bg-contain bg-no-repeat " >

                <motion.div
                    animate={heroTextContainerAnimation}
                >
                    <h6 className='text-orange-500 md:text-xl text-sm font-bold w-full ' >WELCOME TO HYPEBEAT</h6>
                    <h1 className='md:text-6xl text-3xl font-bold mt-4  text-black w-full'>You've Got Questions.  We've Got <span className='text-yellow-400'>Influencer</span></h1>
                    <div className="md:flex md:justify-start md:items-center mt-5 " >
                        <div className=' '>

                            <div className='md:border-t-2 border-slate-700 w-36'>
                                <h1 className=' pt-5 md:text-5xl text-2xl font-bold text-yellow-300 '><CountUp end={500} delay={2} />+</h1>
                                <p className=' pb-5 text-xs md:text-base font-bold text-black'>Happy Client</p>
                            </div>

                        </div>

                        <div className="md:ml-4  lg:p-5 text-xs md:text-base  ">
                            <p className='text-xs md:text-base text-black'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.Proin sit amet  eros mi. Class aptent taciti sociosqu ad litora torquent per  conubia nostra, per inceptos himenaeos. </p>

                            <button className=' mt-8  w-48 text-lg px-4 py-4 font-bold rounded-2xl bg-orange-500 hover:bg-yellow-400  text-white mt-2'>

                                <span className='flex justify-center items-center  '>
                                    <AiFillLike className='mr-3 text-2xl' />
                                    Read More
                                </span>  </button>
                        </div>
                    </div>
                </motion.div>



            </div>
            <div className='lg:flex-1  flex-none'>
                <div className="w-full h-full py-20 bg-[url('https://i.ibb.co/2F7gBFg/bg1-3.png')] bg-contain bg-no-repeat  flex justify-center items-center">


                    <motion.div className="relative m-7 md:m-0 md:my-7  bg-[url('https://i.ibb.co/98Tb1KD/background-1-1.png')]  bg-top  bg-[#FA87A8] rounded-3xl lg:h-[450px] lg:w-[500px] md:w-[700px] md:h-[700px] h-[280px] w-[300px] "
                        animate={heroMainImageAnimation}
                    >

                        <motion.img
                            animate={heroTopImageAnimation}
                            className=' absolute rounded-2xl bg-yellow-500 w-1/3 -top-14 -left-7 ' src={ImageN1} alt="" />
                        <img className="  absolute w-full md:-top-12 -top-6 " src={ImageN2} alt="" />
                        <motion.img
                            animate={heroBottomImageAnimation}
                            className='absolute  rounded-2xl bg-blue-500 w-1/3 -bottom-14 -right-7' src={ImageN3} alt="" />

                    </motion.div>


                </div>
            </div>
        </div>
    );
};

export default HeroHypebeat;