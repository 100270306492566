import { motion, useInView } from 'framer-motion';
import React, { useRef } from 'react';
import { AiFillLike } from 'react-icons/ai';
import image1 from '../../asset/surprised-european-guy-keeps-mouth-opened-from-won-2022-02-06-07-46-51-utc.png'

const promoteMainImage = {
    hidden: { opacity: 0, scale: .75 },
    visible: {
        opacity: 1,
        scale: 1,
        transition: {
            type: 'spring',
            delay: 0.5,
            damping: 3,
            duration: 4,
            stiffness: 60,
            restDelta: 0.001,
            ease: 'easeInOut'
        }
    }
}
const promoteText = {
    hidden: { opacity: 0, scale: .75 },
    visible: {
        opacity: 1,
        scale: 1,
        transition: {
            type: 'spring',
            delay: 0.5,
            damping: 3,
            duration: 5,
            stiffness: 60,
            restDelta: 0.001,
            ease: 'easeInOut'
        }
    }
}

const PromoteHypebeat = () => {
    const ref = useRef(null)
    const isInView = useInView(ref, { once: true })
    return (
        <div ref={ref} className='   lg:h-screen  h-full bg-white py-40 '>
            <div className='container mx-auto h-full flex justify-center items-center '>
                {
                    isInView && <div className=" bg-blue-500  w-[90%]  rounded-3xl flex justify-center items-center  lg:flex-row flex-col">


                        <motion.div
                            variants={promoteMainImage}
                            initial="hidden"
                            animate="visible"
                            className=" relative bg-[url('https://i.ibb.co/98Tb1KD/background-1-1.png')] bg-cover bg-pink-400  h-[53vh] md:h-[500px] w-[95%] lg:h-[500px] lg:w-[80%]  rounded-3xl -top-8 flex justify-center items-center lg:ml-5">
                            <img className=' absolute -top-16 lg:h-[71vh] lg:w-[70vw] md:h-[500px] md:w-[70vw] h-[60vh] w-[100vw]' src={image1} alt="" />
                            {/* <img className=' absolute -top-16 lg:h-[71vh] lg:w-[70vw] md:h-[48vh] md:w-[70vw] h-[60vh] w-[100vw]' src={image1} alt="" /> */}
                        </motion.div>



                        <motion.div
                            variants={promoteText}
                            initial="hidden"
                            animate="visible"
                            className='  flex flex-col justify-center p-10  '>
                            <h2 className=' text-5xl font-bold capitalize text-white'>And On The Eighth Day, God Created Influencer.</h2>
                            <h4 className=' text-2xl font-bold capitalize text-white mt-10'>Promote Your Business To Get Next Level</h4>

                            <button className=' w-40 text-lg px-4 py-4 font-bold rounded-2xl hover:bg-orange-400 bg-yellow-400  text-white mt-5'>

                                <span className='flex justify-center items-center '>
                                    <AiFillLike className='mr-2 h-5 w-5' />
                                    Read More
                                </span>  </button>

                        </motion.div>

                    </div >
                }

            </div>
        </div >

    );
};

export default PromoteHypebeat;