import logo from './logo.svg';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Navbar from './componnts/Navbar/Navbar';
import Hero from './componnts/Hero/Hero';
import Client from './componnts/Client/Client';
import About from './componnts/About/About';
import Success from './componnts/Success/Success';
import WhyUs from './componnts/WhyUs/WhyUs';
import Service from './componnts/Service/Service';
import Promote from './componnts/Promote/Promote';
import Pricing from './componnts/Pricing/Pricing';
import Testimonial from './componnts/Testimonial/Testimonial';
import Blog from './componnts/Blog/Blog';
import Footer from './componnts/Footer/Footer';
import AboutHypebeat from './componnts/AboutHypebeat/AboutHypebeat';
import InfluencersSuccess from './componnts/InfluencersSuccess/InfluencersSuccess';
import WhyHypebeat from './componnts/WhyHypebeat/WhyHypebeat';
import ServiceHypebeat from './componnts/ServiceHypebeat/ServiceHypebeat';
import PromoteHypebeat from './componnts/PromoteHypebeat/PromoteHypebeat';
import BlogHypebeat from './componnts/BlogHypebeat/BlogHypebeat';
import PricingHypebeat from './componnts/PricingHypebeat/PricingHypebeat';
import HeroHypebeat from './componnts/HeroHypebeat/HeroHypebeat';
import Dashboard from './componnts/Dashboard/Dashboard';
import Home from './componnts/FrontPage/Home';


function App() {
  return (
    <div className=' bg-white ' >

      <Navbar />
      <Routes>
        <Route path='/' exact element={<Home />}></Route>
      </Routes>
      <Routes>
        <Route path='/dashboard' exact element={<Dashboard />}></Route>
      </Routes>








      {/* <About /> */}
      {/* <Success />
      <WhyUs />
      <Service />
      <Promote />
      <Pricing />
      <Testimonial />
      <Blog />
      <Footer /> */}

    </div>
  );

}

export default App;