import React from 'react';
import { BsDot } from 'react-icons/bs';

const BlogHypeCard = ({ blog }) => {
    const { image, discription, title } = blog
    return (

        <div className=''>
            <div className=' shadow-2xl rounded-2xl md:mb-5 my-5 grid grid-cols-1 divide-y lg:w-[95%] w-[98%]  bg-white'>
                <div className=' flex flex-col justify-center align-middle '>
                    <img className='  rounded-t-2xl' src={image} alt="" />
                    <h3 className='  md:text-2xl text-base font-bold text-orange-500 md:my-5 md:mx-7 p-3 lg:p-0'>{title}</h3>
                    <p className=' text-base md:mt-1 md:mx-8  text-black p-3 lg:p-0 '>{discription}</p>
                </div>
                <div className=' py-4 px-8 flex items-center justify-start'>

                    <span className=' text-black'>June 5, 2022</span>
                    <span className=' flex justify-center items-center text-black'> <BsDot /> No Comments</span>
                </div>

            </div>

        </div>
    );
};

export default BlogHypeCard;