import React from 'react';
import { motion, useInView } from "framer-motion"


const ServiceCard = ({ service }) => {

    const { serviceName, servicedetail } = service
    return (
        <div className='  '>
            <div className=" w-[305.5px] h-[450px] columns-1  p-10 rounded-3xl bg-orange-500 flex flex-col justify-center items-centers">
                <h3 className='text-center text-3xl font-bold mb-10 text-white'>{serviceName}</h3>
                <p className=' text-center text-base text-white '>{servicedetail}</p>
                <button className=' mx-10 px-4 py-4 font-bold rounded-2xl hover:bg-orange-400 bg-yellow-400  text-white mt-5'>Read More</button>
            </div>
        </div>
    );
};

export default ServiceCard;