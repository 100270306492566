import React from 'react';
import { GoPrimitiveDot } from 'react-icons/go';

const PricingHypeCard = ({ pricingCard }) => {

    const { plans, price, discription } = pricingCard

    return (
        <div className='  w-full   '>
            <div className='flex justify-center items-center  '>

                <div className=' flex flex-col items-center justify-center p-5  '>
                    <div className=' w-[95%] h-[400px]  lg:w-[98%]  p-3  rounded-3xl bg-blue-600 flex flex-col justify-center items-center'>
                        <h2 className=' mb-5 text-lg md:text-2xl text-center font-bold capitalize text-white'>{plans}</h2>
                        <h4 className='text-2xl md:text-5xl font-bold text-center capitalize  text-white'> {price}</h4>
                        <p className=' text-xs md:text-base  text-white'>/project</p>
                        <p className=' my-5 text-xs md:text-base text-center  text-white'>{discription}</p>
                        <button className=' text-white  w-40 text-lg px-4 py-4 font-bold rounded-2xl hover:bg-orange-400 bg-yellow-400  text-white mt-5'>
                            <span className='flex justify-center items-center '>
                                Read More
                            </span>  </button>
                    </div>
                    <div className=' p-6 ' >
                        <h5 className='text-2xl font-bold my-5 capitalize text-black'>package includes</h5>
                        <div className=' w-60 mb-5 border-t-2 border-slate-400 border-dotted'></div>
                        <div className='flex items-center justify-start'>
                            <GoPrimitiveDot className=' text-orange-500 font-extrabold text-2xl ' />
                            <span className=' font-semibold ml-3 capitalize text-black'>Get 1 Influencer for 1 category</span>
                        </div>
                        <div className='flex items-center justify-start'>
                            <GoPrimitiveDot className=' text-orange-500 font-extrabold text-2xl ' />
                            <span className='font-semibold ml-3 capitalize text-black'>1 months contract</span>
                        </div>
                        <div className='flex items-center justify-start'>
                            <GoPrimitiveDot className=' text-orange-500 font-extrabold text-2xl ' />
                            <span className='font-semibold ml-3 capitalize text-black'>3 Active Campaign</span>
                        </div>
                        <div className='flex items-center justify-start'>
                            <GoPrimitiveDot className=' text-orange-500 font-extrabold text-2xl ' />
                            <span className='font-semibold ml-3 capitalize text-black'>Free Platform Access</span>
                        </div>
                        <div className='flex items-center justify-start'>
                            <GoPrimitiveDot className=' text-orange-500 font-extrabold text-2xl ' />
                            <span className='font-semibold ml-3 capitalize text-black'>24/7 Support</span>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    );
};

export default PricingHypeCard;