import React from 'react';
import EmployeeDashboard from './EmployeeDashboard/EmployeeDashboard';


const Dashboard = () => {
    return (
        <div>
            <EmployeeDashboard />

        </div>
    );
};

export default Dashboard;