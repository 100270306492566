import React from 'react';

import Testimonialcard from './Testimonialcard';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import newStyle from './testimonial.css'

const testimonials = [
    {
        id: 1,
        name: 'Dyas Kardinal',
        designation: 'Designer',
        discription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.\r\n',
        image: 'https://i.ibb.co/LvLbkX9/team-1.jpg'
    },
    {
        id: 2,
        name: 'Dyas Kardinal',
        designation: 'Designer',
        discription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.\r\n',
        image: 'https://i.ibb.co/52n9DfN/team-2.jpg'
    },
    {
        id: 3,
        name: 'Dyas Kardinal',
        designation: 'Designer',
        discription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.\r\n',
        image: 'https://i.ibb.co/LvLbkX9/team-1.jpg'
    },
    {
        id: 4,
        name: 'Dyas Kardinal',
        designation: 'Designer',
        discription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.\r\n',
        image: 'https://i.ibb.co/52n9DfN/team-2.jpg'
    },

]

const Testimonial = () => {
    return (
        <div className='relative '>
            <div className=' md:flex md:justify-center md:items-center bg-white'>
                {/* <div className='testimonials-layout md:flex  md:justify-center md:items-center md:p-5 bg-blue-200'>
                    {
                        testimonials.map(testimonial => <Testimonialcard key={testimonial.id} testimonial={testimonial} ></Testimonialcard>)
                    }

                </div> */}
                <div className='testimonials-layout flex flex-col-reverse lg:flex-row lg:justify-center lg:items-center lg:p-5 '>


                    <div className='swiper-container'>
                        <Swiper
                            spaceBetween={0}
                            slidesPerView={1}
                            centeredSlides={true}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            loop={true}
                            modules={[Autoplay, Pagination, Navigation]}
                            breakpoints={{
                                640: {
                                    width: 640,
                                    slidesPerView: 1,

                                },
                                700: {
                                    width: 700,
                                    slidesPerView: 1,

                                },
                                1024: {
                                    width: 1024,
                                    slidesPerView: 3,

                                }
                            }}

                        //     1024: {
                        //         width: 768,
                        //         slidesPerView: 3,
                        //     },
                        // }}

                        >


                            {
                                testimonials.map(testimonial =>
                                    < SwiperSlide >
                                        <div className='testimonial-card-container flex flex-col justify-center bg-white '>
                                            <div className=' relative bg-slate-100  text-black py-8 px-5 m-5 md:text-xl text-sm italic rounded-2xl'>
                                                {testimonial.discription}
                                                <div className=' absolute -bottom-2 left-10  bg-slate-100 h-5 w-5 rotate-45'></div>
                                            </div>

                                            <div className=' mt-2 flex justify-start items-center ml-10 '>

                                                <img className=' mr-5 h-14 w-14 rounded-full' src={testimonial.image} alt="" />
                                                <div className='flex flex-col justify-start align-middle'>
                                                    <span className=' text-lg font-bold text-orange-400'>{testimonial.name}</span>
                                                    <span className='  text-base text-black'>{testimonial.designation}</span>
                                                </div>

                                            </div>
                                        </div>
                                    </SwiperSlide>)
                            }
                        </Swiper>

                    </div>
                    <div className='testimonials-text md:p-10 md:align-middle  bg-white pt-5'>
                        <h6 className=' uppercase text-orange-500 font-bold text-center'>testimonial</h6>
                        <h2 className=' capitalize font-bold md:text-5xl text-2xl text-center text-black'>what they say about our influencer ?</h2>
                    </div>
                </div>

            </div>
        </div >
    );
};

export default Testimonial;