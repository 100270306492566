import React from 'react';
import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { FiBell } from 'react-icons/fi';
import { GiHamburgerMenu } from 'react-icons/gi';
import { ImCross } from 'react-icons/im';
import logo from '../../asset/logo1.png'
import { Link } from 'react-router-dom';

const navigation = [
    { name: 'Dashboard', href: 'dashboard', current: true },
    { name: 'Team', href: '#', current: false },
    { name: 'Projects', href: '#', current: false },
    { name: 'Calendar', href: '#', current: false },
]



const nav = <>

    <Link className='rounded-md text-amber-400 hover:text-amber-400 hover:bg-gray-700 p-2 focus:outline-none focus:ring-2 focus:ring-inset  font-bold ' to='/dashboard'>Dashboard</Link>
    <Link className='rounded-md text-amber-400 hover:text-amber-400 hover:bg-gray-700 p-2 focus:outline-none focus:ring-2 focus:ring-inset  font-bold ' to='/dashboard'>Service</Link>

    {/* <Link className='rounded-md text-amber-400 hover:text-amber-400 hover:bg-gray-700 p-2 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white' to='/blog'>BLOG</Link>
    <Link className='rounded-md text-amber-400 hover:text-amber-400 hover:bg-gray-700 p-2 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white' to='/myportfolio'>MY PORTFOLIO</Link> */}

</>


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}



const Navbar = () => {

    return (
        <div>
            <Disclosure as="nav" className=" bg-black">
                {({ open }) => (
                    <>
                        <div className="max-w-full mx-auto px-2 sm:px-6 lg:px-8 ">
                            <div className="relative flex items-center justify-between h-16">
                                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                                    {/* Mobile menu button*/}
                                    <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-amber-400 hover:text-amber-400 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                        <span className="sr-only">Open main menu</span>
                                        {open ? (

                                            <ImCross className="block h-6 w-6" aria-hidden="true" />
                                        ) : (

                                            <GiHamburgerMenu className="block h-6 w-6" aria-hidden="true" />
                                        )}
                                    </Disclosure.Button>
                                </div>
                                <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                                    <div className="flex-shrink-0 flex items-center">
                                        <img
                                            className="block lg:hidden h-10 w-auto"
                                            src={logo}
                                            alt="Workflow"
                                        />
                                        <img
                                            className="hidden lg:block h-8 w-auto"
                                            src={logo}
                                            alt="Workflow"
                                        />
                                    </div>
                                    <div className="hidden sm:block sm:ml-6">
                                        <div className="flex space-x-4">

                                            {nav}

                                            {/* {navigation.map((item) => (
                                                <a
                                                    key={item.name}
                                                    href={item.href}
                                                    as="link"
                                                    className={classNames(
                                                        item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-amber-400',
                                                        'px-3 py-2 rounded-md text-lg font-medium'
                                                    )}
                                                    aria-current={item.current ? 'page' : undefined}
                                                >
                                                    {item.name}
                                                </a>
                                            ))} */}

                                        </div>
                                    </div>
                                </div>
                                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                                    <button
                                        type="button"
                                        className="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                                    >
                                        <span className="sr-only">View notifications</span>

                                        <FiBell className="h-6 w-6" aria-hidden="true" />
                                    </button>

                                    {/* Profile dropdown */}

                                </div>
                            </div>
                        </div>

                        <Disclosure.Panel className="sm:hidden">
                            <div className="px-2 pt-2 pb-3 space-y-1">
                                {nav}
                                {/* {navigation.map((item) => (
                                    <Disclosure.Button
                                        key={item.name}
                                        as="link"
                                        href={item.href}
                                        className={classNames(
                                            item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                            'block px-3 py-2 rounded-md text-base font-medium'
                                        )}
                                        aria-current={item.current ? 'page' : undefined}
                                    >
                                        {item.name}
                                    </Disclosure.Button>
                                ))} */}
                            </div>
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </div>
    );
};

export default Navbar;